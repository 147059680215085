import React from 'react'

const Invoices = () => {
  return (
    <div>
      job
    </div>
  )
}

export default Invoices
