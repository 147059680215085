export const horizontalCardsData = [
  {
    id: 1,
    cardName: "React card",
  },
  {
    id: 2,
    cardName: "React card",
  },
  {
    id: 3,
    cardName: "React card",
  },
  {
    id: 4,
    cardName: "React card",
  },
  {
    id: 5,
    cardName: "React card",
  },
  {
    id: 6,
    cardName: "React card",
  },
  {
    id: 7,
    cardName: "React card",
  },
];

export const postTableData = [
  {
    id: 1,
    title: "Now You Can Blog From Everywhere",
    date: "Dec 22, 2022",
  },
  {
    id: 2,
    title: "Design your beautiful blog",
    date: "Dec 22, 2022",
  },
  {
    id: 3,
    title: "Grow your blog community",
    date: "Dec 22, 2022",
  },
];

export const toolData = [
  {
    id: 1,
    tool: "Blog Report",
    caption: "Get insides on blog traffic, views, likes, comment and more.",
  },
  {
    id: 2,
    tool: "Blog Report",
    caption: "Get insides on blog traffic, views, likes, comment and more.",
  },
  {
    id: 3,
    tool: "Blog Report",
    caption: "Get insides on blog traffic, views, likes, comment and more.",
  },
  {
    id: 4,
    tool: "Blog Report",
    caption: "Get insides on blog traffic, views, likes, comment and more.",
  },
];

export const writerData = [
  {
    id: 1,
    name: "Yash Barman",
    posts: [
      {
        id: 1,
        title: "my first post",
        category: "Entertainment",
        publishDate: "14 Jan 2023",
      },
      {
        id: 2,
        title: "Ee salla cup nanna de",
        category: "Sports",
        publishDate: "9 Jan 2023",
      },
      {
        id: 3,
        title: "Top records of RCB",
        category: "Sports",
        publishDate: "14 Jan 2023",
      },
      {
        id: 4,
        title: "Top 5 music artists",
        category: "Music",
        publishDate: "24 Jan 2023",
      },
      {
        id: 5,
        title: "Russia vs Ukrain",
        category: "Polits",
        publishDate: "11 Jan 2023",
      },
      {
        id: 6,
        title: "Elon musk mars mission",
        category: "Technology",
        publishDate: "14 Jan 2023",
      },
    ],
    role: "Owner",
    postCount: 3,
    performanceData: [
      ["Month", "Attendence", "Meeting Deadlines", "Quality of Work"],
      [0, 0, 0, 0],
      [1, 20, 60, 55],
      [2, 33, 30, 59],
      [3, 60, 33, 80],
      [4, 50, 65, 60],
      [5, 80, 80, 90],
      [6, 20, 12, 15],
      [7, 100, 90, 95],
      [8, 60, 80, 56],
      [9, 40, 75, 85],
      [10, 20, 66, 36],
      [11, 55, 20, 47],
    ],
    tasksData: [
      [
        { type: "string", label: "Task ID" },
        { type: "string", label: "Task Name" },
        { type: "string", label: "Description" },
        { type: "date", label: "Start Date" },
        { type: "date", label: "End Date" },
        { type: "number", label: "Duration" },
        { type: "number", label: "Percent Complete" },
        { type: "string", label: "Dependencies" },
      ],
      [
        "task1",
        "Task 1",
        "Research and Development",
        new Date(2021, 2, 22),
        new Date(2021, 5, 20),
        null,
        100,
        null,
      ],
      [
        "task2",
        "task 2",
        "Development",
        new Date(2021, 5, 21),
        new Date(2021, 8, 20),
        null,
        100,
        null,
      ],
      [
        "task3",
        "Task 3",
        "Created dashboard",
        new Date(2021, 8, 21),
        new Date(2021, 11, 20),
        null,
        100,
        null,
      ],
      [
        "tash4",
        "Task 4",
        "Created writers section",
        new Date(2021, 11, 21),
        new Date(2022, 2, 21),
        null,
        100,
        null,
      ],
      [
        "task5",
        "Task 5",
        "Intergrated funtionality to writers section",
        new Date(2022, 2, 22),
        new Date(2022, 5, 20),
        null,
        50,
        null,
      ],
      [
        "task6",
        "task 6",
        "created category section",
        new Date(2022, 5, 21),
        new Date(2022, 8, 20),
        null,
        0,
        null,
      ],
      [
        "task7",
        "Task 7",
        "Created Post section",
        new Date(2022, 8, 21),
        new Date(2022, 11, 20),
        null,
        0,
        null,
      ],
      [
        "task8",
        "Task 8",
        "Created Blog section",
        new Date(2022, 11, 21),
        new Date(2023, 2, 21),
        null,
        0,
        null,
      ],
      [
        "task9",
        "Task 9",
        "Integrated Rich Text Editor",
        new Date(2022, 8, 24),
        new Date(2023, 1, 1),
        null,
        100,
        null,
      ],
      [
        "task10",
        "Task 10",
        "Created Editor for writers",
        new Date(2022, 9, 31),
        new Date(2023, 3, 20),
        null,
        14,
        null,
      ],
      [
        "task11",
        "Task 11",
        "Created individual Writers Section",
        new Date(2022, 9, 28),
        new Date(2023, 5, 20),
        null,
        86,
        null,
      ],
      [
        "task12",
        "Task 12",
        "Integration with backend",
        new Date(2022, 9, 8),
        new Date(2022, 5, 21),
        null,
        89,
        null,
      ],
    ],
  },
  {
    id: 2,
    name: "Devendra madankar",
    posts: [
      {
        id: 1,
        title: "my first post",
        category: "Entertainment",
        publishDate: "14 Jan 2023",
      },
      {
        id: 2,
        title: "Ee salla cup nanna de",
        category: "Sports",
        publishDate: "9 Jan 2023",
      },
      {
        id: 3,
        title: "Top records of RCB",
        category: "Sports",
        publishDate: "14 Jan 2023",
      },
      {
        id: 4,
        title: "Top 5 music artists",
        category: "Music",
        publishDate: "24 Jan 2023",
      },
      {
        id: 5,
        title: "Russia vs Ukrain",
        category: "Polits",
        publishDate: "11 Jan 2023",
      },
      {
        id: 6,
        title: "Elon musk mars mission",
        category: "Technology",
        publishDate: "14 Jan 2023",
      },
    ],
    role: "Owner",
    postCount: 6,
    performanceData: [
      ["Month", "Attendence", "Meeting Deadlines", "Quality of Work"],
      [0, 0, 0, 0],
      [1, 20, 60, 55],
      [2, 33, 30, 59],
      [3, 60, 33, 80],
      [4, 50, 65, 60],
      [5, 80, 80, 90],
      [6, 20, 12, 15],
      [7, 100, 90, 95],
      [8, 60, 80, 56],
      [9, 40, 75, 85],
      [10, 20, 66, 36],
      [11, 55, 20, 47],
    ],
    tasksData: [
      [
        { type: "string", label: "Task ID" },
        { type: "string", label: "Task Name" },
        { type: "string", label: "Description" },
        { type: "date", label: "Start Date" },
        { type: "date", label: "End Date" },
        { type: "number", label: "Duration" },
        { type: "number", label: "Percent Complete" },
        { type: "string", label: "Dependencies" },
      ],
      [
        "task1",
        "Task 1",
        "Research and Development",
        new Date(2021, 2, 22),
        new Date(2021, 5, 20),
        null,
        100,
        null,
      ],
      [
        "task2",
        "task 2",
        "Development",
        new Date(2021, 5, 21),
        new Date(2021, 8, 20),
        null,
        100,
        null,
      ],
      [
        "task3",
        "Task 3",
        "Created dashboard",
        new Date(2021, 8, 21),
        new Date(2021, 11, 20),
        null,
        100,
        null,
      ],
      [
        "tash4",
        "Task 4",
        "Created writers section",
        new Date(2021, 11, 21),
        new Date(2022, 2, 21),
        null,
        100,
        null,
      ],
      [
        "task5",
        "Task 5",
        "Intergrated funtionality to writers section",
        new Date(2022, 2, 22),
        new Date(2022, 5, 20),
        null,
        50,
        null,
      ],
      [
        "task6",
        "task 6",
        "created category section",
        new Date(2022, 5, 21),
        new Date(2022, 8, 20),
        null,
        0,
        null,
      ],
      [
        "task7",
        "Task 7",
        "Created Post section",
        new Date(2022, 8, 21),
        new Date(2022, 11, 20),
        null,
        0,
        null,
      ],
      [
        "task8",
        "Task 8",
        "Created Blog section",
        new Date(2022, 11, 21),
        new Date(2023, 2, 21),
        null,
        0,
        null,
      ],
      [
        "task9",
        "Task 9",
        "Integrated Rich Text Editor",
        new Date(2022, 8, 24),
        new Date(2023, 1, 1),
        null,
        100,
        null,
      ],
      [
        "task10",
        "Task 10",
        "Created Editor for writers",
        new Date(2022, 9, 31),
        new Date(2023, 3, 20),
        null,
        14,
        null,
      ],
      [
        "task11",
        "Task 11",
        "Created individual Writers Section",
        new Date(2022, 9, 28),
        new Date(2023, 5, 20),
        null,
        86,
        null,
      ],
      [
        "task12",
        "Task 12",
        "Integration with backend",
        new Date(2022, 9, 8),
        new Date(2022, 5, 21),
        null,
        89,
        null,
      ],
    ],
  },
  {
    id: 3,
    name: "Lokesh Bairagi",
    posts: [
      {
        id: 1,
        title: "my first post",
        category: "Entertainment",
        publishDate: "14 Jan 2023",
      },
      {
        id: 2,
        title: "Ee salla cup nanna de",
        category: "Sports",
        publishDate: "9 Jan 2023",
      },
      {
        id: 3,
        title: "Top records of RCB",
        category: "Sports",
        publishDate: "14 Jan 2023",
      },
      {
        id: 4,
        title: "Top 5 music artists",
        category: "Music",
        publishDate: "24 Jan 2023",
      },
      {
        id: 5,
        title: "Russia vs Ukrain",
        category: "Polits",
        publishDate: "11 Jan 2023",
      },
      {
        id: 6,
        title: "Elon musk mars mission",
        category: "Technology",
        publishDate: "14 Jan 2023",
      },
    ],
    role: "Ex-Owner",
    postCount: 3,
    performanceData: [
      ["Month", "Attendence", "Meeting Deadlines", "Quality of Work"],
      [0, 0, 0, 0],
      [1, 20, 60, 55],
      [2, 33, 30, 59],
      [3, 60, 33, 80],
      [4, 50, 65, 60],
      [5, 80, 80, 90],
      [6, 20, 12, 15],
      [7, 100, 90, 95],
      [8, 60, 80, 56],
      [9, 40, 75, 85],
      [10, 20, 66, 36],
      [11, 55, 20, 47],
    ],
    tasksData: [
      [
        { type: "string", label: "Task ID" },
        { type: "string", label: "Task Name" },
        { type: "string", label: "Description" },
        { type: "date", label: "Start Date" },
        { type: "date", label: "End Date" },
        { type: "number", label: "Duration" },
        { type: "number", label: "Percent Complete" },
        { type: "string", label: "Dependencies" },
      ],
      [
        "task1",
        "Task 1",
        "Research and Development",
        new Date(2021, 2, 22),
        new Date(2021, 5, 20),
        null,
        100,
        null,
      ],
      [
        "task2",
        "task 2",
        "Development",
        new Date(2021, 5, 21),
        new Date(2021, 8, 20),
        null,
        100,
        null,
      ],
      [
        "task3",
        "Task 3",
        "Created dashboard",
        new Date(2021, 8, 21),
        new Date(2021, 11, 20),
        null,
        100,
        null,
      ],
      [
        "tash4",
        "Task 4",
        "Created writers section",
        new Date(2021, 11, 21),
        new Date(2022, 2, 21),
        null,
        100,
        null,
      ],
      [
        "task5",
        "Task 5",
        "Intergrated funtionality to writers section",
        new Date(2022, 2, 22),
        new Date(2022, 5, 20),
        null,
        50,
        null,
      ],
      [
        "task6",
        "task 6",
        "created category section",
        new Date(2022, 5, 21),
        new Date(2022, 8, 20),
        null,
        0,
        null,
      ],
      [
        "task7",
        "Task 7",
        "Created Post section",
        new Date(2022, 8, 21),
        new Date(2022, 11, 20),
        null,
        0,
        null,
      ],
      [
        "task8",
        "Task 8",
        "Created Blog section",
        new Date(2022, 11, 21),
        new Date(2023, 2, 21),
        null,
        0,
        null,
      ],
      [
        "task9",
        "Task 9",
        "Integrated Rich Text Editor",
        new Date(2022, 8, 24),
        new Date(2023, 1, 1),
        null,
        100,
        null,
      ],
      [
        "task10",
        "Task 10",
        "Created Editor for writers",
        new Date(2022, 9, 31),
        new Date(2023, 3, 20),
        null,
        14,
        null,
      ],
      [
        "task11",
        "Task 11",
        "Created individual Writers Section",
        new Date(2022, 9, 28),
        new Date(2023, 5, 20),
        null,
        86,
        null,
      ],
      [
        "task12",
        "Task 12",
        "Integration with backend",
        new Date(2022, 9, 8),
        new Date(2022, 5, 21),
        null,
        89,
        null,
      ],
    ],
  },
  {
    id: 4,
    name: "Sharansh Asati",
    posts: [
      {
        id: 1,
        title: "my first post",
        category: "Entertainment",
        publishDate: "14 Jan 2023",
      },
      {
        id: 2,
        title: "Ee salla cup nanna de",
        category: "Sports",
        publishDate: "9 Jan 2023",
      },
      {
        id: 3,
        title: "Top records of RCB",
        category: "Sports",
        publishDate: "14 Jan 2023",
      },
      {
        id: 4,
        title: "Top 5 music artists",
        category: "Music",
        publishDate: "24 Jan 2023",
      },
      {
        id: 5,
        title: "Russia vs Ukrain",
        category: "Polits",
        publishDate: "11 Jan 2023",
      },
      {
        id: 6,
        title: "Elon musk mars mission",
        category: "Technology",
        publishDate: "14 Jan 2023",
      },
    ],
    role: "Sub Owner",
    postCount: 2,
    performanceData: [
      ["Month", "Attendence", "Meeting Deadlines", "Quality of Work"],
      [0, 0, 0, 0],
      [1, 20, 60, 55],
      [2, 33, 30, 59],
      [3, 60, 33, 80],
      [4, 50, 65, 60],
      [5, 80, 80, 90],
      [6, 20, 12, 15],
      [7, 100, 90, 95],
      [8, 60, 80, 56],
      [9, 40, 75, 85],
      [10, 20, 66, 36],
      [11, 55, 20, 47],
    ],
    tasksData: [
      [
        { type: "string", label: "Task ID" },
        { type: "string", label: "Task Name" },
        { type: "string", label: "Description" },
        { type: "date", label: "Start Date" },
        { type: "date", label: "End Date" },
        { type: "number", label: "Duration" },
        { type: "number", label: "Percent Complete" },
        { type: "string", label: "Dependencies" },
      ],
      [
        "task1",
        "Task 1",
        "Research and Development",
        new Date(2021, 2, 22),
        new Date(2021, 5, 20),
        null,
        100,
        null,
      ],
      [
        "task2",
        "task 2",
        "Development",
        new Date(2021, 5, 21),
        new Date(2021, 8, 20),
        null,
        100,
        null,
      ],
      [
        "task3",
        "Task 3",
        "Created dashboard",
        new Date(2021, 8, 21),
        new Date(2021, 11, 20),
        null,
        100,
        null,
      ],
      [
        "tash4",
        "Task 4",
        "Created writers section",
        new Date(2021, 11, 21),
        new Date(2022, 2, 21),
        null,
        100,
        null,
      ],
      [
        "task5",
        "Task 5",
        "Intergrated funtionality to writers section",
        new Date(2022, 2, 22),
        new Date(2022, 5, 20),
        null,
        50,
        null,
      ],
      [
        "task6",
        "task 6",
        "created category section",
        new Date(2022, 5, 21),
        new Date(2022, 8, 20),
        null,
        0,
        null,
      ],
      [
        "task7",
        "Task 7",
        "Created Post section",
        new Date(2022, 8, 21),
        new Date(2022, 11, 20),
        null,
        0,
        null,
      ],
      [
        "task8",
        "Task 8",
        "Created Blog section",
        new Date(2022, 11, 21),
        new Date(2023, 2, 21),
        null,
        0,
        null,
      ],
      [
        "task9",
        "Task 9",
        "Integrated Rich Text Editor",
        new Date(2022, 8, 24),
        new Date(2023, 1, 1),
        null,
        100,
        null,
      ],
      [
        "task10",
        "Task 10",
        "Created Editor for writers",
        new Date(2022, 9, 31),
        new Date(2023, 3, 20),
        null,
        14,
        null,
      ],
      [
        "task11",
        "Task 11",
        "Created individual Writers Section",
        new Date(2022, 9, 28),
        new Date(2023, 5, 20),
        null,
        86,
        null,
      ],
      [
        "task12",
        "Task 12",
        "Integration with backend",
        new Date(2022, 9, 8),
        new Date(2022, 5, 21),
        null,
        89,
        null,
      ],
    ],
  },
  {
    id: 5,
    name: "Khelesh Bhalekar",
    posts: [
      {
        id: 1,
        title: "my first post",
        category: "Entertainment",
        publishDate: "14 Jan 2023",
      },
      {
        id: 2,
        title: "Ee salla cup nanna de",
        category: "Sports",
        publishDate: "9 Jan 2023",
      },
      {
        id: 3,
        title: "Top records of RCB",
        category: "Sports",
        publishDate: "14 Jan 2023",
      },
      {
        id: 4,
        title: "Top 5 music artists",
        category: "Music",
        publishDate: "24 Jan 2023",
      },
      {
        id: 5,
        title: "Russia vs Ukrain",
        category: "Polits",
        publishDate: "11 Jan 2023",
      },
      {
        id: 6,
        title: "Elon musk mars mission",
        category: "Technology",
        publishDate: "14 Jan 2023",
      },
    ],
    role: "Owner",
    postCount: 7,
    performanceData: [
      ["Month", "Attendence", "Meeting Deadlines", "Quality of Work"],
      [0, 0, 0, 0],
      [1, 20, 60, 55],
      [2, 33, 30, 59],
      [3, 60, 33, 80],
      [4, 50, 65, 60],
      [5, 80, 80, 90],
      [6, 20, 12, 15],
      [7, 100, 90, 95],
      [8, 60, 80, 56],
      [9, 40, 75, 85],
      [10, 20, 66, 36],
      [11, 55, 20, 47],
    ],
    tasksData: [
      [
        { type: "string", label: "Task ID" },
        { type: "string", label: "Task Name" },
        { type: "string", label: "Description" },
        { type: "date", label: "Start Date" },
        { type: "date", label: "End Date" },
        { type: "number", label: "Duration" },
        { type: "number", label: "Percent Complete" },
        { type: "string", label: "Dependencies" },
      ],
      [
        "task1",
        "Task 1",
        "Research and Development",
        new Date(2021, 2, 22),
        new Date(2021, 5, 20),
        null,
        100,
        null,
      ],
      [
        "task2",
        "task 2",
        "Development",
        new Date(2021, 5, 21),
        new Date(2021, 8, 20),
        null,
        100,
        null,
      ],
      [
        "task3",
        "Task 3",
        "Created dashboard",
        new Date(2021, 8, 21),
        new Date(2021, 11, 20),
        null,
        100,
        null,
      ],
      [
        "tash4",
        "Task 4",
        "Created writers section",
        new Date(2021, 11, 21),
        new Date(2022, 2, 21),
        null,
        100,
        null,
      ],
      [
        "task5",
        "Task 5",
        "Intergrated funtionality to writers section",
        new Date(2022, 2, 22),
        new Date(2022, 5, 20),
        null,
        50,
        null,
      ],
      [
        "task6",
        "task 6",
        "created category section",
        new Date(2022, 5, 21),
        new Date(2022, 8, 20),
        null,
        0,
        null,
      ],
      [
        "task7",
        "Task 7",
        "Created Post section",
        new Date(2022, 8, 21),
        new Date(2022, 11, 20),
        null,
        0,
        null,
      ],
      [
        "task8",
        "Task 8",
        "Created Blog section",
        new Date(2022, 11, 21),
        new Date(2023, 2, 21),
        null,
        0,
        null,
      ],
      [
        "task9",
        "Task 9",
        "Integrated Rich Text Editor",
        new Date(2022, 8, 24),
        new Date(2023, 1, 1),
        null,
        100,
        null,
      ],
      [
        "task10",
        "Task 10",
        "Created Editor for writers",
        new Date(2022, 9, 31),
        new Date(2023, 3, 20),
        null,
        14,
        null,
      ],
      [
        "task11",
        "Task 11",
        "Created individual Writers Section",
        new Date(2022, 9, 28),
        new Date(2023, 5, 20),
        null,
        86,
        null,
      ],
      [
        "task12",
        "Task 12",
        "Integration with backend",
        new Date(2022, 9, 8),
        new Date(2022, 5, 21),
        null,
        89,
        null,
      ],
    ],
  },
];
export const HashTagData = [
  {
    id: 1,
    hashtag: "#playbold",
    catagory: "sports",
    postCount: 33,
  },
  {
    id: 1,
    hashtag: "#virat Kholi",
    catagory: "sports",
    postCount: 33,
  },
  {
    id: 1,
    hashtag: "#Namma RCB",
    catagory: "sports",
    postCount: 33,
  },
  {
    id: 1,
    hashtag: "#RCB",
    catagory: "sports",
    postCount: 33,
  },
  {
    id: 1,
    hashtag: "#we will win",
    catagory: "sports",
    postCount: 33,
  },
  {
    id: 1,
    hashtag: "#Stay strong",
    catagory: "sports",
    postCount: 33,
  },
  {
    id: 1,
    hashtag: "#king kholi",
    catagory: "sports",
    postCount: 33,
  },

  {
    id: 1,
    hashtag: "#yash barman",
    catagory: "sports",
    postCount: 33,
  },
  {
    id: 1,
    hashtag: "#playbold",
    catagory: "sports",
    postCount: 33,
  },
  {
    id: 1,
    hashtag: "#virat Kholi",
    catagory: "sports",
    postCount: 33,
  },
  {
    id: 1,
    hashtag: "#Namma RCB",
    catagory: "sports",
    postCount: 33,
  },
  {
    id: 1,
    hashtag: "#RCB",
    catagory: "sports",
    postCount: 33,
  },
  {
    id: 1,
    hashtag: "#we will win",
    catagory: "sports",
    postCount: 33,
  },
  {
    id: 1,
    hashtag: "#Stay strong",
    catagory: "sports",
    postCount: 33,
  },
  {
    id: 1,
    hashtag: "#king kholi",
    catagory: "sports",
    postCount: 33,
  },

  {
    id: 1,
    hashtag: "#yash barman",
    catagory: "sports",
    postCount: 33,
  },
  {
    id: 1,
    hashtag: "#playbold",
    catagory: "sports",
    postCount: 33,
  },
  {
    id: 1,
    hashtag: "#virat Kholi",
    catagory: "sports",
    postCount: 33,
  },
  {
    id: 1,
    hashtag: "#Namma RCB",
    catagory: "sports",
    postCount: 33,
  },
  {
    id: 1,
    hashtag: "#RCB",
    catagory: "sports",
    postCount: 33,
  },
  {
    id: 1,
    hashtag: "#we will win",
    catagory: "sports",
    postCount: 33,
  },
  {
    id: 1,
    hashtag: "#Stay strong",
    catagory: "sports",
    postCount: 33,
  },
  {
    id: 1,
    hashtag: "#king kholi",
    catagory: "sports",
    postCount: 33,
  },

  {
    id: 1,
    hashtag: "#yash barman",
    catagory: "sports",
    postCount: 33,
  },
  {
    id: 1,
    hashtag: "#playbold",
    catagory: "sports",
    postCount: 33,
  },
  {
    id: 1,
    hashtag: "#virat Kholi",
    catagory: "sports",
    postCount: 33,
  },
  {
    id: 1,
    hashtag: "#Namma RCB",
    catagory: "sports",
    postCount: 33,
  },
  {
    id: 1,
    hashtag: "#RCB",
    catagory: "sports",
    postCount: 33,
  },
  {
    id: 1,
    hashtag: "#we will win",
    catagory: "sports",
    postCount: 33,
  },
  {
    id: 1,
    hashtag: "#Stay strong",
    catagory: "sports",
    postCount: 33,
  },
  {
    id: 1,
    hashtag: "#king kholi",
    catagory: "sports",
    postCount: 33,
  },

  {
    id: 1,
    hashtag: "#yash barman",
    catagory: "sports",
    postCount: 33,
  },
  {
    id: 1,
    hashtag: "#playbold",
    catagory: "sports",
    postCount: 33,
  },
  {
    id: 1,
    hashtag: "#virat Kholi",
    catagory: "sports",
    postCount: 33,
  },
  {
    id: 1,
    hashtag: "#Namma RCB",
    catagory: "sports",
    postCount: 33,
  },
  {
    id: 1,
    hashtag: "#RCB",
    catagory: "sports",
    postCount: 33,
  },
  {
    id: 1,
    hashtag: "#we will win",
    catagory: "sports",
    postCount: 33,
  },
  {
    id: 1,
    hashtag: "#Stay strong",
    catagory: "sports",
    postCount: 33,
  },
  {
    id: 1,
    hashtag: "#king kholi",
    catagory: "sports",
    postCount: 33,
  },

  {
    id: 1,
    hashtag: "#yash barman",
    catagory: "sports",
    postCount: 33,
  },
];

export const catagoryData = [
  {
    rank: 1,
    catagory: "sports",
    noOfTeams: 6,
    postCount: 46,
  },
  {
    rank: 1,
    catagory: "music",
    noOfTeams: 6,
    postCount: 46,
  },
  {
    rank: 1,
    catagory: "entertainment",
    noOfTeams: 6,
    postCount: 46,
  },
  {
    rank: 1,
    catagory: "knowledge",
    noOfTeams: 6,
    postCount: 46,
  },
  {
    rank: 1,
    catagory: "history",
    noOfTeams: 6,
    postCount: 46,
  },
  {
    rank: 1,
    catagory: "education",
    noOfTeams: 6,
    postCount: 46,
  },
  {
    rank: 1,
    catagory: "sports",
    noOfTeams: 6,
    postCount: 46,
  },
  {
    rank: 1,
    catagory: "music",
    noOfTeams: 6,
    postCount: 46,
  },
  {
    rank: 1,
    catagory: "entertainment",
    noOfTeams: 6,
    postCount: 46,
  },
  {
    rank: 1,
    catagory: "knowledge",
    noOfTeams: 6,
    postCount: 46,
  },
  {
    rank: 1,
    catagory: "history",
    noOfTeams: 6,
    postCount: 46,
  },
  {
    rank: 1,
    catagory: "education",
    noOfTeams: 6,
    postCount: 46,
  },
  {
    rank: 1,
    catagory: "sports",
    noOfTeams: 6,
    postCount: 46,
  },
  {
    rank: 1,
    catagory: "music",
    noOfTeams: 6,
    postCount: 46,
  },
  {
    rank: 1,
    catagory: "entertainment",
    noOfTeams: 6,
    postCount: 46,
  },
  {
    rank: 1,
    catagory: "knowledge",
    noOfTeams: 6,
    postCount: 46,
  },
  {
    rank: 1,
    catagory: "history",
    noOfTeams: 6,
    postCount: 46,
  },
  {
    rank: 1,
    catagory: "education",
    noOfTeams: 6,
    postCount: 46,
  },
  {
    rank: 1,
    catagory: "sports",
    noOfTeams: 6,
    postCount: 46,
  },
  {
    rank: 1,
    catagory: "music",
    noOfTeams: 6,
    postCount: 46,
  },
  {
    rank: 1,
    catagory: "entertainment",
    noOfTeams: 6,
    postCount: 46,
  },
  {
    rank: 1,
    catagory: "knowledge",
    noOfTeams: 6,
    postCount: 46,
  },
  {
    rank: 1,
    catagory: "history",
    noOfTeams: 6,
    postCount: 46,
  },
  {
    rank: 1,
    catagory: "education",
    noOfTeams: 6,
    postCount: 46,
  },
  {
    rank: 1,
    catagory: "sports",
    noOfTeams: 6,
    postCount: 46,
  },
  {
    rank: 1,
    catagory: "music",
    noOfTeams: 6,
    postCount: 46,
  },
  {
    rank: 1,
    catagory: "entertainment",
    noOfTeams: 6,
    postCount: 46,
  },
  {
    rank: 1,
    catagory: "knowledge",
    noOfTeams: 6,
    postCount: 46,
  },
  {
    rank: 1,
    catagory: "history",
    noOfTeams: 6,
    postCount: 46,
  },
  {
    rank: 1,
    catagory: "education",
    noOfTeams: 6,
    postCount: 46,
  },
  {
    rank: 1,
    catagory: "sports",
    noOfTeams: 6,
    postCount: 46,
  },
  {
    rank: 1,
    catagory: "music",
    noOfTeams: 6,
    postCount: 46,
  },
  {
    rank: 1,
    catagory: "entertainment",
    noOfTeams: 6,
    postCount: 46,
  },
  {
    rank: 1,
    catagory: "knowledge",
    noOfTeams: 6,
    postCount: 46,
  },
  {
    rank: 1,
    catagory: "history",
    noOfTeams: 6,
    postCount: 46,
  },
  {
    rank: 1,
    catagory: "education",
    noOfTeams: 6,
    postCount: 46,
  },
];

export const performanceData = [
  {
    id: 1,
    team: "Duccan",
    size: "6",
    postCount: 89,
  },
  {
    id: 2,
    team: "south works",
    size: "9",
    postCount: 80,
  },
  {
    id: 3,
    team: "the writes",
    size: "4",
    postCount: 75,
  },
  {
    id: 4,
    team: "king writes",
    size: "6",
    postCount: 70,
  },
  {
    id: 5,
    team: "blow writes",
    size: "6",
    postCount: 65,
  },
  {
    id: 6,
    team: "side writes",
    size: "2",
    postCount: 56,
  },
  {
    id: 7,
    team: "kinght writes",
    size: "23",
    postCount: 55,
  },
  {
    id: 1,
    team: "Duccan",
    size: "6",
    postCount: 89,
  },
  {
    id: 2,
    team: "south works",
    size: "9",
    postCount: 80,
  },
  {
    id: 3,
    team: "the writes",
    size: "4",
    postCount: 75,
  },
  {
    id: 4,
    team: "king writes",
    size: "6",
    postCount: 70,
  },
  {
    id: 5,
    team: "blow writes",
    size: "6",
    postCount: 65,
  },
  {
    id: 6,
    team: "side writes",
    size: "2",
    postCount: 56,
  },
  {
    id: 7,
    team: "kinght writes",
    size: "23",
    postCount: 55,
  },
  {
    id: 1,
    team: "Duccan",
    size: "6",
    postCount: 89,
  },
  {
    id: 2,
    team: "south works",
    size: "9",
    postCount: 80,
  },
  {
    id: 3,
    team: "the writes",
    size: "4",
    postCount: 75,
  },
  {
    id: 4,
    team: "king writes",
    size: "6",
    postCount: 70,
  },
  {
    id: 5,
    team: "blow writes",
    size: "6",
    postCount: 65,
  },
  {
    id: 6,
    team: "side writes",
    size: "2",
    postCount: 56,
  },
  {
    id: 7,
    team: "kinght writes",
    size: "23",
    postCount: 55,
  },
  {
    id: 1,
    team: "Duccan",
    size: "6",
    postCount: 89,
  },
  {
    id: 2,
    team: "south works",
    size: "9",
    postCount: 80,
  },
  {
    id: 3,
    team: "the writes",
    size: "4",
    postCount: 75,
  },
  {
    id: 4,
    team: "king writes",
    size: "6",
    postCount: 70,
  },
  {
    id: 5,
    team: "blow writes",
    size: "6",
    postCount: 65,
  },
  {
    id: 6,
    team: "side writes",
    size: "2",
    postCount: 56,
  },
  {
    id: 7,
    team: "kinght writes",
    size: "23",
    postCount: 55,
  },
  {
    id: 1,
    team: "Duccan",
    size: "6",
    postCount: 89,
  },
  {
    id: 2,
    team: "south works",
    size: "9",
    postCount: 80,
  },
  {
    id: 3,
    team: "the writes",
    size: "4",
    postCount: 75,
  },
  {
    id: 4,
    team: "king writes",
    size: "6",
    postCount: 70,
  },
  {
    id: 5,
    team: "blow writes",
    size: "6",
    postCount: 65,
  },
  {
    id: 6,
    team: "side writes",
    size: "2",
    postCount: 56,
  },
  {
    id: 7,
    team: "kinght writes",
    size: "23",
    postCount: 55,
  },
];

export const categoryData = [
  {
    id: 1,
    title: "Digital Research",
    subCategories: [
      { name: "Movie Reviews", postCount: 42 },
      { name: "Photos", postCount: 12 },
      { name: "Music", postCount: 4 },
      { name: "Beauty Pageants", postCount: 5 },
      { name: "Movie Reviews", postCount: 17 },
      { name: "Events", postCount: 2 },
    ],
  },
  {
    id: 2,
    title: "DevOps",
    subCategories: [
      { name: "Femina", postCount: 12 },
      { name: "Women", postCount: 42 },
      { name: "Travel", postCount: 26 },
      { name: "Recipes", postCount: 24 },
      { name: "Health & Fitness", postCount: 31 },
    ],
  },
  {
    id: 3,
    title: "Sales Intelligence",
    subCategories: [
      { name: "India Business", postCount: 12 },
      { name: "Stpartus", postCount: 12 },
      { name: "International Business", postCount: 12 },
    ],
  },
  {
    id: 4,
    title: "Legal Consultant",
    subCategories: [
      { name: "Indian", postCount: 42 },
      { name: "World", postCount: 62 },
      { name: "Russia vs Ukrain", postCount: 24 },
    ],
  },
  {
    id: 5,
    title: "Marketing Automation",
    subCategories: [
      { name: "Cricket", postCount: 24 },
      { name: "ICC WT20", postCount: 22 },
      { name: "Football", postCount: 32 },
      { name: "TOISA", postCount: 2 },
    ],
  },
  {
    id: 6,
    title: "Education",
    subCategories: [
      { name: "Bennett University", postCount: 24 },
      { name: "Students", postCount: 24 },
      { name: "Study Abroad", postCount: 24 },
      { name: "Jobs", postCount: 24 },
    ],
  },
  {
    id: 7,
    title: "Music",
    subCategories: [
      { name: "Indian", postCount: 24 },
      { name: "International", postCount: 24 },
      { name: "Folk", postCount: 24 },
      { name: "Indi", postCount: 24 },
    ],
  },
  {
    id: 8,
    title: "Social",
    subCategories: [
      { name: "Viral News", postCount: 24 },
      { name: "Trending News", postCount: 24 },
    ],
  },
  {
    id: 9,
    title: "Technology",
    subCategories: [
      { name: "Latest News", postCount: 24 },
      { name: "Tech Photos", postCount: 24 },
      { name: "Reviews", postCount: 24 },
      { name: "Latest Gadgets", postCount: 24 },
      { name: "Compare", postCount: 24 },
      { name: "Gadgets", postCount: 24 },
    ],
  },
];
